import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="new-source-form-manager"
export default class extends Controller {
  static targets = ["select", "linkForm", "searchForm"]

  connect() {
    this.change() // Run on connect to set the default state
  }

  change() {
    const selectedType = this.selectTarget.value
    if (selectedType === 'Link') {
      this.linkFormTarget.classList.remove('hidden')
      this.searchFormTarget.classList.add('hidden')
      this.linkFormTarget.querySelector('[name="source[source_type]"]').value = selectedType
    } else {
      this.linkFormTarget.classList.add('hidden')
      this.searchFormTarget.classList.remove('hidden')
      this.searchFormTarget.querySelector('[name="source_type"]').value = selectedType
    }
  }
}
