// app/javascript/controllers/model_definitions_schema_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["schemaFields", "fieldTemplate"];

  addField(event) {
    event.preventDefault();
    const content = this.fieldTemplateTarget.innerHTML.replace(/NEW_FIELD/g, new Date().getTime());
    this.schemaFieldsTarget.insertAdjacentHTML('beforeend', content);
  }

  removeField(event) {
    event.preventDefault();
    event.target.closest(".field-group").remove();
  }
}
