import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy-to-clipboard"
export default class extends Controller {
  static targets = ["copySource"]

  connect() {
    console.log('here')
  }

  copy(event) {
    const linkInput = this.copySourceTarget;
    linkInput.select();
    document.execCommand('copy');

    // Optional: Show a tooltip or change the button text briefly
    event.currentTarget.textContent = 'Copied!';
    // setTimeout(() => event.currentTarget.textContent = 'Copy', 2000);
  }
}
